<template>
  <div class="company">
    <section class="section">
      <div class="video-container" data-simulate-touch="false" data-loop="false"
        data-autoplay="5500">
        <div class="video-wrapper">
          <div class="video-slide slide_video context-dark video-bg-overlay">
            <div style="
                    position: absolute;
                    z-index: -1;
                    top: 0px;
                    left: 0px;
                    bottom: 0px;
                    right: 0px;
                    overflow: hidden;
                    background-size: cover;
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-image: none;
                  ">
                <video loop muted class="video" autoplay="autoplay" playsinline src="video/video-lg.mp4">
                  <source src="video/video-lg" type="video/mp4" />
                  <img src="video/video-md.jpg" title="Your browser does not support video" />
                </video>
              </div>
            <!-- RD Video-->
            <div class="vide_bg novi-vide" data-vide-bg="video/video-lg" data-vide-options="posterType: jpg">
              <div class="text-center">
                <div class="container">
                  <h2 data-caption-animate="fadeInUpSmall" class="title-size">{{ $t('lang.company.section_1.banner.title') }}</h2>
                  <h1 class="text-width-2 block-centered sub-text-size font-off" data-caption-animate="fadeInUpSmall"
                    data-caption-delay="200" v-html="$t('lang.company.section_1.banner.subtitle')">
                  </h1>

                  <div class="group-lg group-middle">
                    <a class="button button-primary" data-caption-animate="fadeInUpSmall" data-caption-delay="350"
                      href="/cuentanos"
                      @click="clickSend">{{
                      $t('lang.company.section_1.banner.btn') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section novi-bg novi-bg-img section-md bg-default text-center" id="section-see-features">
      <div class="container">
        <div class="row row-50 justify-content-sm-center">
          <div class="col-md-12 col-lg-11 about-us">
            <h2>
              <b>{{ $t('lang.company.section_2.title') }}</b>
            </h2>
            <p v-html="$t('lang.company.section_2.description_1')"></p>
            <p v-html="$t('lang.company.section_2.description_2')"></p>
            <p v-html="$t('lang.company.section_2.description_3')"></p>
          </div>
        </div>
        <div class="row steps justify-content-lg-center">
          <!--  NEW CODE -->
          <div class="content-steps">
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-secundary number-content text-center number-content-one">
                <span class="option-step">{{ $t('lang.company.section_2.step') }}</span>
                <br />
                <span class="number-step">01</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-secundary banner-content banner-content-one arrow-right-secundary">
                <div class="row-banners">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.company.section_2.step_1.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.company.section_2.step_1.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-third number-content text-center number-content-two">
                <span class="option-step">{{ $t('lang.company.section_2.step') }}</span>
                <br />
                <span class="number-step">02</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-third banner-content banner-content-two arrow-right-third">
                <div class="row-banners">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.company.section_2.step_2.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.company.section_2.step_2.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-principal number-content text-center number-content-three">
                <span class="option-step">{{ $t('lang.company.section_2.step') }}</span>
                <br />
                <span class="number-step">03</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-principal banner-content banner-content-three arrow-right-principal">
                <div class="row-banners">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row">
                      <div class="col-md-12 title-principal">{{ $t('lang.company.section_2.step_3.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.company.section_2.step_3.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fas fa-lightbulb-o"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-fourth number-content text-center number-content-four">
                <span class="option-step">{{ $t('lang.company.section_2.step') }}</span>
                <br />
                <span class="number-step">04</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-fourth banner-content banner-content-four arrow-right-fourth">
                <div class="row-banners">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.company.section_2.step_4.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.company.section_2.step_4.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fa fa-file-text-o"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-fifth number-content text-center number-content-five">
                <span class="option-step">{{ $t('lang.company.section_2.step') }}</span>
                <br />
                <span class="number-step">05</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-fifth banner-content banner-content-five arrow-right-fifth">
                <div class="row-banners">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.company.section_2.step_5.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.company.section_2.step_5.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fa fa-calendar"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-banners banners justify-content-center">
              <div class="col-12 col-md-2 bgcolor-sixth number-content text-center number-content-six">
                <span class="option-step">{{ $t('lang.company.section_2.step') }}</span>
                <br />
                <span class="number-step">06</span>
              </div>
              <div class="col-12 col-md-7 bgcolor-sixth banner-content banner-content-six arrow-right-sixth">
                <div class="row-banners">
                  <div class="col-12 col-md-10 padding-content">
                    <div class="row-banners">
                      <div class="col-md-12 title-principal">{{ $t('lang.company.section_2.step_6.title') }}</div>
                    </div>
                    <div class="row-banners">
                      <div class="col-md-12 text-content">
                        {{ $t('lang.company.section_2.step_6.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 icon-content">
                    <i class="fa fa-pencil"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END NEW CODE -->
        </div>
      </div>
    </section>

    <section class="section novi-bg novi-bg-img section-md text-center" id="section-why-us"
      style="background-color: #f8f8f8 !important;">
      <div class="container">
        <div class="row row-50 justify-content-sm-center">
          <div class="col-md-12 col-lg-11 about-us">
            <h2>
              <b>{{ $t('lang.company.section_3.title') }}</b>
            </h2>
            <p v-html="$t('lang.company.section_3.description_1')"></p>
            <p v-html="$t('lang.company.section_3.description_2')"></p>
            <p v-html="$t('lang.company.section_3.description_3')"></p>
          </div>
        </div>
      </div>
    </section>

    <section class="section novi-bg novi-bg-img section-md text-center" id="section-why-us" style="padding-top: 0px">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-md-12 col-lg-12 about-us section-sm">
            <h3 style="color: #888;">
              {{ $t('lang.company.section_4.description') }}
            </h3>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3">
            <!-- Thumb creative-->
            <div class="thumb-creative">
              <div class="thumb-creative__inner">
                <div class="thumb-creative__front">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/01.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 v-html="$t('lang.company.section_4.card_1.title')"></h6>
                  </div>
                </div>
                <div class="thumb-creative__back">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/01.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 class="thumb-creative__title" v-html="$t('lang.company.section_4.card_1.title')"></h6>
                    <p>
                      {{ $t('lang.company.section_4.card_1.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3">
            <!-- Thumb creative-->
            <div class="thumb-creative">
              <div class="thumb-creative__inner">
                <div class="thumb-creative__front">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/02.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 v-html="$t('lang.company.section_4.card_2.title')"></h6>
                  </div>
                </div>
                <div class="thumb-creative__back">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/02.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 class="thumb-creative__title" v-html="$t('lang.company.section_4.card_2.title')"></h6>
                    <p>
                      {{ $t('lang.company.section_4.card_2.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3">
            <!-- Thumb creative-->
            <div class="thumb-creative">
              <div class="thumb-creative__inner">
                <div class="thumb-creative__front">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/03.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 v-html="$t('lang.company.section_4.card_3.title')"></h6>
                  </div>
                </div>
                <div class="thumb-creative__back">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/03.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 class="thumb-creative__title" v-html="$t('lang.company.section_4.card_3.title')"></h6>
                    <p>
                      {{ $t('lang.company.section_4.card_3.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3">
            <!-- Thumb creative-->
            <div class="thumb-creative">
              <div class="thumb-creative__inner">
                <div class="thumb-creative__front">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/04.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 v-html="$t('lang.company.section_4.card_4.title')"></h6>
                  </div>
                </div>
                <div class="thumb-creative__back">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/04.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 class="thumb-creative__title" v-html="$t('lang.company.section_4.card_4.title')"></h6>
                    <p>
                      {{ $t('lang.company.section_4.card_4.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3">
            <!-- Thumb creative-->
            <div class="thumb-creative">
              <div class="thumb-creative__inner">
                <div class="thumb-creative__front">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/05.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 v-html="$t('lang.company.section_4.card_5.title')"></h6>
                  </div>
                </div>
                <div class="thumb-creative__back">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/05.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 class="thumb-creative__title" v-html="$t('lang.company.section_4.card_5.title')"></h6>
                    <p>
                      {{ $t('lang.company.section_4.card_5.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3">
            <!-- Thumb creative-->
            <div class="thumb-creative">
              <div class="thumb-creative__inner">
                <div class="thumb-creative__front">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/06.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 v-html="$t('lang.company.section_4.card_6.title')"></h6>
                  </div>
                </div>
                <div class="thumb-creative__back">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/06.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 class="thumb-creative__title" v-html="$t('lang.company.section_4.card_6.title')"></h6>
                    <p>
                      {{ $t('lang.company.section_4.card_6.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3">
            <!-- Thumb creative-->
            <div class="thumb-creative">
              <div class="thumb-creative__inner">
                <div class="thumb-creative__front">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/07.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 v-html="$t('lang.company.section_4.card_7.title')"></h6>
                  </div>
                </div>
                <div class="thumb-creative__back">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/07.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 class="thumb-creative__title" v-html="$t('lang.company.section_4.card_7.title')"></h6>
                    <p>
                      {{ $t('lang.company.section_4.card_7.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3">
            <!-- Thumb creative-->
            <div class="thumb-creative">
              <div class="thumb-creative__inner">
                <div class="thumb-creative__front">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/08.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 v-html="$t('lang.company.section_4.card_8.title')"></h6>
                  </div>
                </div>
                <div class="thumb-creative__back">
                  <figure class="thumb-creative__image-wrap">
                    <img class="thumb-creative__image img-big" src="@/assets/images/img-big/08.png"
                      alt="En búsqueda de candidatos perfectos" width="480" height="361" />
                  </figure>
                  <div class="thumb-creative__content" style="backface-visibility: hidden;">
                    <h6 class="thumb-creative__title" v-html="$t('lang.company.section_4.card_8.title')"></h6>
                    <p>
                      {{ $t('lang.company.section_4.card_8.description') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'Company',
  metaInfo: {
    title: 'Buscas talentos IT para tu empresa | Connecting Tech People',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '🔥 Consulta las mejores ofertas de trabajo para formar tu Empresa.'
      }
    ]
  },
  methods: {

    clickSend() {
      this.$ga.event({
        'eventCategory': 'banner-empresas-1',
        'eventAction': '/cuentanos',
        'eventLabel': 'button-aqui-empresas'
      });
    },
  }
});
</script>
